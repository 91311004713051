import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.Element
import org.w3c.dom.TouchEvent
import org.w3c.dom.events.WheelEvent
import org.w3c.dom.get
import kotlin.js.Date
import kotlin.math.max
import kotlin.math.min

fun main() = start {
    logo()
    box(0, "supachain",
        "Our foundational framework for building AI applications. ",
        "")
    box(1, "supamatic", "An Ai borw", "down")
    box(2, "niche", "","down")
    swipeNavigator()
    debug()

    State.initialize()
}


fun Element.logo() =
    "logo" {
        img {
            src = "logo.svg"
            alt = "Logo"
            width = 40
            height = 40
        }
        h1 { +"anything ai" }
    }


fun Element.box(index: Int, name: String, description: String, stateClass: String) =
    name {
        className = "box $stateClass"
        div {
            h1 { +name }

            addEventListener("mouseover", {
                println("Hovered over the div")
            })
        }

        State.nextItem {
            if (index == State.currentSelection) {
                removeClass("up", "down")
            } else if (index > State.currentSelection) {
                removeClass("up")
                addClass("down")
            } else {
                removeClass("down")
                addClass("up")
            }
        }
    }

val debug = document.createElement("div").apply { className = "debug" }

fun Element.debug() = append(debug)

typealias ThresholdAction = (ThresholdType) -> Unit

enum class ThresholdType { Within, Above, Below }


value class Threshold<T : Number>(private val data: Triple<T, T, ThresholdAction>) {
    constructor(min: T, max: T, thresholdAction: ThresholdAction) : this(Triple(min, max, thresholdAction))

    val min: T get() = data.first
    val max: T get() = data.second
    val action: ThresholdAction get() = data.third

    companion object {
        operator fun invoke(around: Short, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))

        operator fun invoke(around: Byte, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))

        operator fun invoke(around: Int, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))

        operator fun invoke(around: Long, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))

        operator fun invoke(around: Float, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))

        operator fun invoke(around: Double, thresholdAction: ThresholdAction) =
            Threshold(Triple(-around, around, thresholdAction))
    }
}

operator fun Threshold<Short>.invoke(value: Short) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

operator fun Threshold<Byte>.invoke(value: Byte) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

operator fun Threshold<Int>.invoke(value: Int) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

operator fun Threshold<Long>.invoke(value: Long) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

operator fun Threshold<Float>.invoke(value: Float) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

operator fun Threshold<Double>.invoke(value: Double) {
    if (value < min) action(ThresholdType.Below)
    else if (value > max) action(ThresholdType.Above)
    else action(ThresholdType.Within)
}

fun Element.swipeNavigator() =
    "swiper" {
        span { }
    }.apply {

        var lastOn = 0.0
        var hasReset = false

        val scrollAction = Threshold(8.0) {
            when (it) {
                ThresholdType.Within -> if (!hasReset && Date().getTime() - lastOn > 120) {
                    hasReset = true
                    removeClass("on", "prev", "next")
                    removeClass("prev")
                    print("Within")
                }

                ThresholdType.Above -> {
                    if (hasReset) {
                        addClass("on", "next")
                        State.next()
                        println("next")
                    }
                    hasReset = false
                    lastOn = Date().getTime()
                }

                ThresholdType.Below -> {
                    if (hasReset) {
                        addClass("on", "prev")
                        State.prev()
                        println("prev")
                    }
                    hasReset = false
                    lastOn = Date().getTime()
                }
            }
        }

        State.nextFrame {
            scrollAction(State.wheelY)
            if (State.touchStatus == TouchStatus.On)  scrollAction(State.touchY.toDouble()/10) else scrollAction(0.0)
        }
    }

enum class TouchStatus { On, Off, Cancel }

object State {
    var wheelX = 0.0
    var wheelY = 0.0
    var touchX = 0
    var touchY = 0
    var touchStatus = TouchStatus.Off
    var currentSelection = 0
    var maxSelections = 3

    private val timeActions = mutableListOf<() -> Unit>()
    private val itemActions = mutableListOf<() -> Unit>()

    fun nextFrame(action: () -> Unit) {
        timeActions.add(action)
    }

    fun nextItem(action: () -> Unit) {
        itemActions.add(action)
    }

    private fun change() {
        timeActions.forEach { it() }
        window.requestAnimationFrame { change() }
    }

    fun initialize() {
        window.addEventListener("wheel", {
            wheelX = (it as WheelEvent).deltaX
            wheelY = (it as WheelEvent).deltaY
        })

        window.addEventListener("touchmove", {
            it as TouchEvent
            val touch = it.touches[0] ?: throw IllegalStateException("touch even corrupted")
            val deltaX = touch.clientX - touchX
            val deltaY = touch.clientY - touchY

            touchX = touch.clientX
            touchY = touch.clientY
        })

        window.addEventListener("touchstart", {
            it as TouchEvent
            touchStatus = TouchStatus.On
            val touch = it.touches[0] ?: throw IllegalStateException("touch even corrupted")
            touchX = touch.clientX
            touchY = touch.clientY
        })

        window.addEventListener("touchend", {
            touchStatus = TouchStatus.Off
        })

        window.addEventListener("touchCancel", {
            touchStatus = TouchStatus.Cancel
        })

        change()
    }

    fun next() {
        currentSelection = min(currentSelection + 1, maxSelections - 1)
        itemActions.forEach { it() }
    }

    fun prev() {
        currentSelection = max(0, currentSelection - 1)
        itemActions.forEach { it() }
    }

}