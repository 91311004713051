import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.appendElement
import kotlinx.dom.appendText
import org.w3c.dom.*
import org.w3c.dom.svg.SVGImageElement

context(Element)
operator fun String.unaryPlus() = appendText(this)

context(HTMLUListElement)
operator fun String.unaryPlus() = appendElement("li") { appendText(this@unaryPlus) }
        as HTMLLIElement

context(HTMLUListElement)
operator fun Element.unaryPlus() = appendElement("li") { append(this@unaryPlus) }
        as HTMLLIElement

context(Element)
operator fun String.invoke(builder: HTMLDivElement.() -> Unit) = appendElement("div") {
    this as HTMLDivElement
    id = this@invoke
    builder()
} as HTMLDivElement

fun Element.div(builder: HTMLDivElement.() -> Unit) = appendElement("div") {
    this as HTMLDivElement
    builder(this)
} as HTMLDivElement

fun Element.span(builder: HTMLSpanElement.() -> Unit) = appendElement("span") {
    this as HTMLSpanElement
    builder(this)
} as HTMLSpanElement

fun Element.h1(builder: HTMLHeadingElement.() -> Unit) = appendElement("h1") {
    this as HTMLHeadingElement
    builder(this)
} as HTMLHeadingElement

fun Element.h2(builder: HTMLHeadingElement.() -> Unit) = appendElement("h2") {
    this as HTMLHeadingElement
    builder(this)
} as HTMLHeadingElement

fun Element.h3(builder: HTMLHeadingElement.() -> Unit) = appendElement("h3") {
    this as HTMLHeadingElement
    builder(this)
} as HTMLHeadingElement

fun Element.h4(builder: HTMLHeadingElement.() -> Unit) = appendElement("h4") {
    this as HTMLHeadingElement
    builder(this)
} as HTMLHeadingElement

fun Element.p(builder: HTMLParagraphElement.() -> Unit) = appendElement("p") {
    this as HTMLParagraphElement
    builder(this)
} as HTMLParagraphElement

fun Element.ul(builder: HTMLUListElement.() -> Unit) = appendElement("ul") {
    this as HTMLUListElement
    builder(this)
} as HTMLUListElement

fun Element.li(builder: HTMLLIElement.() -> Unit) = appendElement("li") {
    this as HTMLLIElement
    builder(this)
} as HTMLLIElement

fun Element.img(builder: HTMLImageElement.() -> Unit) = appendElement("img") {
    this as HTMLImageElement
    builder(this)
} as HTMLImageElement

fun Element.svg(builder: SVGImageElement.() -> Unit) = appendElement("svg") {
    this as SVGImageElement
    builder(this)
} as SVGImageElement

fun HTMLElement.pageBoundary() = style.apply {
    width = "90%"
    left = "50%"
    transform = "translateX(-50%)"
    position = "relative"
}

fun HTMLUListElement.example(point: String, explanations: String) =
    appendElement("li") {
        appendElement("strong") { appendText("$point: ") }
        appendText(explanations)
    }

fun start(setUp: Element.() -> Unit) {
    window.onload = {
        document.body?.setUp()
    }
}